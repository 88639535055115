@import url("https://fonts.gstatic.com/s/playfairdisplay/v29/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDTbtPY_Q.woff2");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

.reception {
  padding: 9rem 6rem 4rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-bg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

@media only screen and (min-width: 1910px) {
  .reception {
    padding: 120px 17rem 50px 13rem;
  }
}

.img-reception {
  height: 540px;
  width: 540px;
  margin-left: 70px;
  margin-right: 70px;
  position: relative;
  z-index: 2;
}
/* //pour faire le overlay */
.overlay-image {
  width: 75vw;
  position: relative;
}

@media only screen and (min-width: 1910px) {
  .overlay-image {
    width: 55vw;
  }
}

.copy-box-home-vision2 {
  width: fit-content !important;
  background-color: var(--text-white) !important;
  color: var(--text) !important;
  font-family: "Open Sans", sans-serif !important;
}

.text-reception {
  display: flex;
  flex-direction: column;
  color: var(var(--text-white));
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30%;
  background-color: var(--secondary-bg);
  border-top: 15px solid var(--primary-bg);
  border-right: 15px solid var(--primary-bg);
  margin-left: 70px;
  z-index: 3;
}
.reception-number {
  font-size: 64px;
  font-weight: 600;
  font-family: "Open Sans";
  color: var(--text);
}
.reception-description span {
  display: block;
}
.reception-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 2em;
  letter-spacing: 3px;
  font-family: "Open Sans";
  color: var(--text);
  margin-bottom: 1rem;
}
.side-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  width: 85%;
}
.side-paragraphe {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1.5px;
  line-height: 1.5em;
}
.small-text {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 4px;
  color: var(--text-primary);
}
.trait {
  padding-top: 15px;
  border-bottom: 1px solid var(--text-white);
  margin-bottom: 15px;
  width: 100%;
}
.title {
  font-size: 50px;
  font-family: "Playfair Display";
  line-height: 1.3em;
  font-weight: 400;
}
.text-footer {
  display: flex;
  justify-content: space-between;
}
.side-icons {
  display: flex;
  flex-direction: column;
  margin-left: 20%;
}
.side-mail {
  display: flex;
  flex-direction: column;
}
.side-email-hotel {
  font-size: 14px;
  letter-spacing: 2px;
  font-family: "Haffer";
}
.side-icons-span {
  display: flex;
  justify-content: space-around;
}
.icons-color {
  color: var(--text-white);
  font-size: 24px;
  margin: 0px 7px;
}
.text-footer-gras {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 4px;
  color: #1c1c1c;
}
/* //////////implement responsive design */
@media screen and (min-width: 200px) and (max-width: 767px) {
  .reception {
    flex-direction: column;
    padding: 0px;
    padding-top: 120px;
    padding-bottom: 40px;
  }

  .overlay-image {
    width: 100%;
    padding: 0px 30px;
  }
  .img-reception {
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-left: 0px;
    height: 385px;
  }
  .text-reception {
    width: 50%;
    left: 25%;
    margin: 0px;
    border-style: none;
  }
  .reception-description span {
    display: inline;
  }
  .side-text {
    margin-top: 40px;
    justify-content: center;
    text-align: center;
  }
  .title {
    font-size: 30px;
  }
  .title3 {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    line-height: 50px !important;
  }
}

.solutions-part {
  display: flex;
  padding: 40px 80px;
  padding-bottom: 0px;
  flex-wrap: wrap;
  overflow: hidden;
}

.solutions1 {
  width: 100%;
  display: flex;
  gap: 2%;
  margin-bottom: 40px;

  transition: scale 300ms ease-in-out !important;
}

.solutions1:hover {
  transition: transform 300ms ease-in-out;
}

.solution-image {
  width: 49%;
  height: 100%;
}

.solution-data {
  margin-top: 20px;
}

.solution-content {
  width: 49%;
  padding: 20px 80px;
  padding-left: 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 1910px) {
  .solutions-part {
    padding: 40px 17rem;
  }
}

@media only screen and (max-width: 767px) {
  .solutions1 {
    flex-direction: column;
    gap: 0px;
  }

  .solution-image {
    width: 100%;
  }
  .solution-content {
    width: 100%;
    padding: 20px;
    padding-top: 6px;
    padding-bottom: 30px;
  }
  .solutions-part {
    padding: 40px 10px;
    padding-bottom: 0px;
  }

  .mob-sol-img {
    order: 1;
  }

  .mob-sol-content {
    order: 2;
  }
}

/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.social-part {
  padding: 3rem 5rem 5rem 6rem;
  display: flex;
  gap: 40px;
}
.social-list {
  width: 23%;
}

.social-left {
  width: 30%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.social-right {
  width: 100%;
  padding: 5rem 6rem 5rem 6rem;
}

.social-team-desc {
  margin-top: 20px;
  text-align: center;
}

.social-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: left;
  gap: 30px;
}

.social-img-div {
  padding: 5px;
  border-style: groove;
  border-radius: 500px;
  overflow: hidden;
  width: 75%;
  margin: auto;
}

.social-image {
  height: 60%;
  border-radius: 500px;
  padding: 0px auto;
  transition: transform 300ms ease-in-out;
  z-index: 4;
  position: relative;
  background: radial-gradient(white, #0e4067);
  box-shadow: -1px -1px 0px grey;
}

.social-item {
  cursor: pointer;
  font-size: 42px;
  margin-bottom: 20px;
  position: relative;
}

.social-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
  background: rgb(0 0 0 / 41%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
}

.social-icon svg {
  width: 30px;
  height: 30px;
  fill: #fff;
}

.social-item:hover .social-icon {
  opacity: 1;
}

.content-person {
  padding: 20px 20px;
  padding-bottom: 20px;
  margin-top: -80px;
  text-align: center;
  position: relative;
  z-index: 9 !important;
  transition: transform 300ms ease-in-out;
}

.content-person:hover {
  z-index: 9;
  border: 2px solid grey;
  border-radius: 12px;
  background: #101c4c;
}

.content-person:hover .social-head {
  color: var(--text-white);
}

.content-person:hover .social-data {
  color: var(--text-white) !important;
}

.content-person:hover .social-img-div {
  border: 2px dashed white;
  transform: scale(1);
}

.content-person:hover .social-image {
  background: radial-gradient(#0e4067, white);
  box-shadow: 0px 0px 6px 3px grey;
  transform: scale(1.3);
}

.content-person:hover {
}
/* .social-item:hover .social-image {
  margin-top: -5px;
  transform: scale(1.1);
  box-shadow: 0px 2px 12px 1px grey;
} */

/* .social-item:hover .content-person {
  background: linear-gradient(
    to left,
    aliceblue 40%,
    #e5edf9 60%,
    #c4daf3 95%,
    #173152 100%
  );
  box-shadow: -1px 9px 15px 0px grey;
} */

.social-data {
}

.social-head {
  padding: 5px 20px;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  font-size: 20px !important;
  z-index: 99;
  position: relative;
}
/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .social-part {
    padding: 50px 18rem;
    text-align: center;
  }

  .social-right {
    padding: 50px 13rem;
  }
}

@media only screen and (min-width: 768px) {
  .social-item {
    width: 30%;
    margin: 0px 1%;
    margin-bottom: 20px;
    text-align: center;
    z-index: 9;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 768px) {
  .social-items {
    display: flex;
    flex-direction: column;
  }
  .social-item {
    width: 90%;
    margin: 0px auto;
    margin-bottom: 30px;
  }
  .social-content {
    margin-top: 0px;
  }
  .social-part {
    padding: 50px 15px;
    flex-direction: column;
    padding-bottom: 0px;
  }

  .social-left {
    width: 100%;
  }

  .social-right {
    width: 100%;
    padding: 40px 30px;
  }
  .social-image {
    margin-bottom: 0px;
  }
  .social-list {
    width: 100%;
  }
}

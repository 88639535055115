@import "./Break.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css?family=Oswald:light,bold&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inter:regular,bold&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akronim&family=Bungee+Shade&family=Cabin+Sketch:wght@400;700&family=Concert+One&family=Ewert&family=Lato:wght@400;700&family=Open+Sans:wght@700&family=Oswald:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akronim&family=Big+Shoulders+Inline+Text:wght@900&family=Bungee+Shade&family=Cabin+Sketch:wght@400;700&family=Cairo+Play:wght@900&family=Concert+One&family=Ewert&family=Foldit:wght@400;500&family=Jacques+Francois+Shadow&family=Lato:wght@400;700&family=Lilita+One&family=Open+Sans:wght@700&family=Oswald:wght@500;600;700&family=Rubik+80s+Fade&family=Zilla+Slab+Highlight:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akronim&family=Big+Shoulders+Inline+Text:wght@900&family=Bungee+Shade&family=Cabin+Sketch:wght@400;700&family=Cairo+Play:wght@900&family=Cinzel+Decorative:wght@700&family=Concert+One&family=Ewert&family=Foldit:wght@400;500&family=Jacques+Francois+Shadow&family=Lato:wght@400;700&family=Lilita+One&family=Open+Sans:wght@700&family=Oswald:wght@500;600;700&family=Passion+One&family=Rubik+80s+Fade&family=Zilla+Slab+Highlight:wght@700&display=swap"); // ---------- main-styles - buttons. --------------

// ******** ------------ External Fonts ------------- *******

body {
  cursor: default;
}

.main-title {
  font-family: Open Sans, sans-serif;
  font-size: 42px;
  color: var(--text-primary);
  font-weight: 400;
  text-shadow: -2px 2px rgba(128, 128, 128, 0.3098039216);
  // font-size: 4.0625rem;
  font-weight: 700;
  line-height: 1.25;
  position: relative;
}

.main-title1 {
  font-family: "Open Sans", sans-serif;
  font-size: 32px;
  color: var(--text-gold);
  font-weight: 600;
}

.main-paragraph {
  font-family: "Inter";
  font-size: 18px;
  opacity: 100%;
  line-height: 30px;
}

.heading {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
}

.custom-btn {
  // width: 130px;
  // height: 40px;

  color: var(--text-white);
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  // box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
  //   7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  font-family: "Haffer";
  padding: 10px 52px;
  font-size: 18px;
  outline: none;
}

.main-btn {
  background-color: var(--primary-btn) !important;
  border: 1px solid transparent;
  z-index: 1;
}
.main-btn:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: var(--text-white);
  border-radius: 5px;
  // box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
  //   7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.main-btn:hover {
  color: var(--text-primary);
  border: 1px solid var(--primary-bg);
}
.main-btn:hover:after {
  left: 0;
  width: 100%;
}
.main-btn:active {
  top: 2px;
}

//----------------------------

.custom-btn1 {
  // width: 130px;
  // height: 40px;

  color: var(--text-primary);
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  // box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
  //   7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  font-family: "Haffer";
  padding: 10px 52px;
  font-size: 18px;
  outline: none;
}

.main-btn1 {
  background-color: var(--text-white) !important;
  border: 1px solid var(--primary-btn);
  z-index: 1;
}
.main-btn1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: var(--primary-btn);
  border-radius: 5px;
  // box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
  //   7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.main-btn1:hover {
  color: var(--text-white);
  border: 1px solid var(--primary-bg);
}
.main-btn1:hover:after {
  left: 0;
  width: 100%;
}
.main-btn1:active {
  top: 2px;
}

//-----------------------------main -styles - others ------------

.green-btn {
  background-color: var(--primary-btn);
  color: var(--text-white);
  border-radius: 4px;
  padding: 6px 20px;
  font-size: 22px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  text-transform: uppercase;
  width: fit-content;
}

.green-btn:hover {
  background-color: transparent;
  color: var(--text-primary);
  border: 2px dashed var(--text-primary);
}

.back-btn {
  background-color: var(--primary-btn);
  color: var(--text-white);
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  padding: 3px 20px;
  font-size: 20px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  text-transform: uppercase;
  margin-top: 18px !important;
  position: absolute;
  margin-left: -6px;
}

.title1 {
  font-size: 45px;
  line-height: 72px;
  color: var(--text-white);
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  padding: 20px 0px;
}

.heading {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.paragraph {
  font-family: "Open Sans", sans-serif;
  letter-spacing: normal !important;
  font-size: 18px !important;
  color: var(--text) !important;
  line-height: 170%;
}

.paragraph-white {
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: normal !important;
  font-size: 18px !important;
  color: var(--text-white) !important;
  line-height: 160%;
}

.team-btn {
  background-color: var(--text-white);
  padding: 5px 10px;
  font-family: "Open Sans";
  font-weight: bold;
}

.team-btn:hover {
  background-color: var(--primary-btn);
  color: var(--text-white);
}

@media only screen and (max-width: 767px) {
  .custom-btn {
    padding: 10px 40px !important;
  }
  .custom-btn1 {
    padding: 10px 40px !important;
  }

  .title1 {
    font-size: 40px;
    margin-top: 60px;
    margin-bottom: -20px;
  }

  .main-title {
    font-size: 38px;
  }
}

.btn-side {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: var(--primary-btn) !important;
  border: none;
  cursor: pointer;
  font-family: "Jost", Arial, Helvetica, sans-serif;
}

.btn-side span {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 100%;
  padding: 9px 20px;
  transition: 0.3s;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

/*--- btn-1 ---*/
.btn-11::before {
  background-color: var(--text-white);
  transition: 0.3s ease-out;
  position: absolute;
  content: "";
}
.btn-11 span {
  color: var(--text);

  transition: 0.2s 0.1s;
}
.btn-11 span:hover {
  color: var(--text-white);
  transition: 0.2s 0.1s;
  outline: 0 !important;
  outline-offset: 0;
}

/* 4. hover-filled-slide-right */
.btn-side.hover-filled-slide-right::before {
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  content: "";
}
.btn-side.hover-filled-slide-right:hover::before {
  width: 0%;
  position: absolute;
  content: "";
  outline: 0 !important;
  outline-offset: 0;
}

.t-padding {
  padding: 0px 110px;
}

//----------------------------------------- general

//----------------------------------------- layout

//----------------------------------------- Recruitment

.recruitmentContainer {
  display: flex;
  gap: 2em;
  margin: 0px;
  width: 100% !important;
  max-width: 100%;
  padding: 0rem 7rem;
  .steps {
    flex-basis: 90%;
    // flex-grow: 1;
  }
  @include media(">xl") {
    margin: 0 auto;
  }
  @include media(">md") {
    display: flex;
    gap: 2em;
  }
}

@media only screen and (min-width: 1910px) {
  .recruitmentContainer {
    padding: 0rem 17rem;
  }
  .t-padding {
    padding: 0px 16rem;
  }
}

@media only screen and (max-width: 767px) {
  .recruitmentContainer {
    padding: 0px 30px;
    flex-direction: column;
  }
  .main-title1 {
    font-size: 22px;
  }
  .t-padding {
    padding: 0px 20px;
  }
}
form {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
}
.formTitle {
  margin-bottom: 2em;
}

.formContanier {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
}

.stepsIndication {
  color: var(--text);
  font-weight: 300;
}

.stepTitle {
  color: var(--text);
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1.5em;
  font-weight: 400;
}
.fieldContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.2em;
}
.fieldLabel::after {
  content: " *";
  color: red;
  font-weight: bold;
}
label.optional::after {
  content: "";
}

// ------------------------------------- text field, text box and drop down items styling
.inputField {
  border: #a3a3a3 0.1em solid;
  border-radius: 0.4em;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0.3em 0.4em;
}
.inputField:focus,
.inputField:hover {
  border: #3992ff 0.1em solid;
}
.dropDownItems {
  color: var(--text);
  background-color: var(--text-black);
}

// ------------------------------------- main stepper Styling

.mainStepperButtonsContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1.5em;
  margin-top: 1em;
}
.primaryButton {
  background-color: var(--primary-btn);
  color: var(--text-white);
  border-radius: 4em;
  padding: 0.6em 2em;
  cursor: pointer;
  border: none;
}
.secondaryButton {
  color: var(--secondary-btn);
  cursor: pointer;
  background-color: transparent;
  border: none;
}

// ------------------------------------- small special stepper Styling

.smallSpecialStepperContainer {
  position: sticky;
  top: 5em;

  overflow-x: scroll;
  scrollbar-width: none;
  padding: 1em 0;
  @include media(">=md") {
    display: none;
  }
}
.smallSpecialStepperButtonsContainer {
  display: flex;
  gap: 0.5em;
  min-width: 35em;
}
.smallSpecialStepperContainer::-webkit-scrollbar {
  display: none;
}
.smallSpecialStepperButton {
  border: 0;
  border-radius: 2em;
  padding: 0.3em;
  flex: 1 1 0;
}

.smallSpecialStepperDisapledButton {
  background-color: #c8f4f4;
  cursor: not-allowed;
}

// ------------------------------------- big special stepper Styling
.bigSpecialStepperConatiner {
  position: sticky;
  top: 7.5em;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  @include media("<md") {
    display: none;
  }
}
.bigSpecialStepperButtonContainer {
  display: flex;
  align-items: center;
  gap: 0.9em;
  margin-bottom: 0.9em;
}
.bigSpecialStepperIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3em;
  height: 3em;

  border-radius: 100%;
}
.bigSpecialStepperActiveIconContainer {
  border: solid 0.1em #002549;
}
.bigSpecialStepperDisabledIconContainer {
  border: solid 0.1em #002549;
  cursor: not-allowed;
}
.bigSpecialStepperButtonLabel {
  max-width: 10em;
}
.bigSpecialStepperDisapbledButtonLabel {
  color: #363636;
  cursor: not-allowed;
}
.bigSpecialStepperInactiveButtonLabel {
  color: var(--text);
}
.hello {
  background-color: red;
}

.bigSpecialStepperActiveIcon {
  color: var(--text);
}
.bigSpecialStepperDisapledIcon {
  color: #363636;
}

// ------------------------------------- text field, text box and drop down items styling

.inputField {
  border-radius: 0.4em;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0.3em 0.4em;
}
.invalidInputField {
  border: red 0.1em solid;
  border-radius: 0.4em;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0.3em 0.4em;
}
.inputField:focus,
.inputField:hover {
  border: #3992ff 0.1em solid;
}
.dropDownItems {
  color: var(--text-white);
  background-color: #121316;
}

// ------------------------------------- radio button styling
.radioSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2em;
  margin-top: 0.5em;
}
.radioItemInput {
  display: none;
  position: relative;
}
.radioItemInput:checked ~ .radioItemLabel::before {
  outline: #3992ff 0.2em solid;
  background-color: #3992ff;
}
.radioItemInput:checked + .radioItemLabel::after {
  background-color: #3992ff;
  outline: border;
  display: inline;
}
.radioItemLabel {
  position: relative;
  padding-left: 1.3em;
  cursor: pointer;
}
.radioItemLabel::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  left: -0.02em;
  top: 0.3em;
  background-color: transparent;
  border: #121316 0.2em solid;
  outline: #a3a3a3 0.1em solid;
  border-radius: 50%;
}
.radioItemLabel:hover::before {
  outline: #3992ff 0.1em solid;
}

// ------------------------------------- special range styling
.specialRangeContainer {
  display: flex;
  gap: 0.2em;
  margin: 0.5em 0 1em 0;
}
.specialRangeItem {
  display: flex;
  width: 100%;
  cursor: pointer;
}
.specialRangeInput {
  display: none;
}
.specialRangeInput:checked + .specialRangeLabel {
  background-color: #3992ff;
}
.specialRangeInput:hover + .specialRangeLabel {
  background-color: #3992ff;
}
.specialRangeLabel {
  background-color: #a3a3a3;
  text-align: center;
  flex-grow: 1;
}

// ------------------------------------------ validation messages

.validationErrorMessage {
  padding: 0.5em 0.5em;
  margin: 1em 0;
  background-color: rgb(64, 20, 20);
  color: #fbbebe;
  border-radius: 0.5em;
}
.errorMsgIcon {
  background-color: rgb(64, 20, 20);
  margin-right: 0.7em;
}

//-------------------------------------------

.twoInputColumns {
  display: flex;
  flex-direction: row;
  gap: 3em;
  @include media("<=md") {
    display: block;
  }
  .column {
    @include media(">md") {
      min-width: 5em;
    }
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
  }
}
//--------------------------------- popup
.popupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #121316b2;
  inset: 0;
  z-index: 1;
}
.popup {
  position: fixed;
  background-color: var(--text-white);
  color: var(--text-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding: 1em 2em;
  border-radius: 1em;
  span {
    color: var(--text);
    background-color: #fff;
  }
}

.popupButtonsContainer {
  display: flex;
  background-color: #fff;
  gap: 1em;
}

//--------------------------------------------------------- alerts
.messageContainer {
  position: fixed;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}
.message {
  padding: 1em 1em;
  background-color: var(--text-white);
  min-width: 20em;
  border-radius: 0.7em;
  @include media(">md") {
    width: 35em;
  }
  .messageIcon {
    background-color: #fff;
    margin-right: 0.7em;
  }
  .messageContent {
    color: var(--text);
    background-color: #fff;
    margin-bottom: 1em;
  }
  .buttonConatiner {
    background-color: #fff;
    display: flex;
    justify-content: end;
    align-items: right;
  }
}

//-----------

.menu-design-img {
  width: 230px;
  height: 180px;
  margin: auto;
  box-shadow: 0px 2px 4px 0px grey;
}

.menu-design-img:hover {
  cursor: pointer;
}

.menu-design-modal {
  z-index: 99;
  width: 60%;
  height: 60%;
  margin: auto;
  position: relative;
}

.menu-design-img-modal {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 120px;
}

.menu-slider-div {
  margin: 30px 0px;
}

.menu-design-modal-close-btn {
  position: absolute;
  top: 18%;
  right: -8px;
  background: red;
  border-radius: 30px;
  padding: 3px 10px;
  color: var(--text-white);
}

.new-items-div {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0px;
  gap: 20px;
}

.menu-item-img {
  width: 30%;
}

.benefits-title {
  font-family: "Open Sans", sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.website-benefits-div {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin: 30px 0px;
}

.website-benefit {
  width: 31%;
  box-shadow: 2px 1px 4px 0px grey;
  background-size: cover;
  background-position: 50%;
  min-height: 320px;
  max-height: 400px;
}

.online-service {
  width: 45%;
  box-shadow: 2px 1px 4px 0px grey;
  background-size: cover;
  background-position: 50%;
  min-height: 290px;
  max-height: 350px;
}

.online-benefit-item {
  margin: 20px 0px 0px;
  width: 49%;
  box-shadow: 1px 1px 2px grey;
  padding: 20px 10px;
  border-left: 4px solid #278cd2;
}

.online-benefit-item:hover {
  box-shadow: 2px 2px 8px grey;
}

.benefits-ul {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
}

.website-heading {
  background-color: #278cd2;
  color: var(--text-white) !important;
  font-family: "Oswald";
  font-size: 24px;
  width: fit-content;
  padding: 5px 10px;
  margin-top: 80px;
  text-transform: uppercase;
}

.website-para {
  display: none;
  color: var(--text) !important;
  font-family: "Open Sans", sans-serif;
  letter-spacing: normal !important;
  font-size: 16px !important;
  line-height: 170%;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  padding: 10px;
  margin: 20px;
  border-radius: 10px !important;
  background: white;
  border: 1px solid var(--text-primary);
  box-shadow: 0px 0px 13px 8px #00000099;
}

.website-benefit:hover .website-para {
  display: block;
}

.online-service:hover .website-para {
  display: block;
}

@media only screen and (min-width: 1910px) {
  .menu-design-modal {
    width: 50%;
    height: 50%;
  }
  .menu-design-modal-close-btn {
    top: 23%;
  }
}

@media only screen and (max-width: 767px) {
  .website-benefit {
    width: 100%;
  }

  .online-benefit-item {
    width: 100%;
  }

  .online-service {
    width: 100%;
  }
}

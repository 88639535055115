.careers1 {
  padding: 6rem 6rem 2rem 6rem;
  /* background-image: url(./../WhoWeAre/whowearebg3.jpg); */
  background-color: var(--primary-bg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.particles-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* CareerCard.css */
.career-card-12 {
  /* Your existing styles for the career-card-12 component */

  /* Add the following styles for the particles wrapper */
  position: relative;
  width: 100%; /* Set the desired width for the particles wrapper */

  overflow: hidden;
}

.career-img {
  position: relative;
  z-index: 2;
  margin-top: -300px;
}

@media only screen and (min-width: 1910px) {
  .careers1 {
    padding: 100px 17rem 60px;
  }
}

@media only screen and (max-width: 767px) {
  .careers1 {
    padding: 40px 30px;
    padding-top: 100px;
  }
  .career-img {
    margin-top: -130px;
    transform: scale(1.5);
  }
}

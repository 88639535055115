/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.why-part {
  padding: 25px 115px 40px;
  border-top: 1px solid #80808036;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.why-items {
  display: flex;
  flex-direction: row;
  text-align: left;
}

.why-image {
  width: 100%;
  margin: 0px auto;
}

.why-image {
  width: 200px; /* Set a fixed width */
  height: 200px; /* Set a fixed height */
  object-fit: cover; /* This property ensures the image covers the entire container while maintaining its aspect ratio */
  margin: 0px auto;
}

.why-content {
  background-color: var(--text-white);
}

.why-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  background-color: var(--text-white);
  width: -moz-fit-content;
  padding: 20px 22px 0px;
  color: var(--text);
}

.why-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--text);
  padding: 15px 20px 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.why-item {
  cursor: pointer;
  font-size: 42px;
  margin-bottom: 20px;
  box-shadow: 0 4px 20px #00000014;
}

/* .why-item:hover .why-paragraph {
  backdrop-filter: blur(50px);
} */

.why-btn {
  font-family: "Open Sans";
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
  color: #044b61;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .why-part {
    padding: 50px 18rem;
  }
}

@media only screen and (min-width: 768px) {
  .why-item {
    width: 23%;
    /* margin: 0px 1%; */
    overflow: hidden;
    background-color: var(--text-white);
    padding: 0px;
    background-size: cover;
    box-shadow: 0px 0px 2px grey;
    margin-right: 2%;
  }
  .why-item-image-div {
    width: 100%;
    overflow: hidden;
  }

  .why-item:hover .why-image {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  .why-item-image-div .why-image {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    width: 100%;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 768px) {
  .why-items {
    display: flex;
    flex-direction: column;
  }
  .why-item {
    width: 100%;
    margin: 0px auto;
    margin-bottom: 30px;
    box-shadow: 0px 0px 20px grey;
  }
  .why-content {
    margin-top: 0px;
  }
  .why-part {
    padding: 40px 30px 20px;
  }

  .why-image {
    width: 100%;
  }
}

.services-hero-part {
  padding-top: 10rem !important;
  background: var(--primary-bg);
}

.services-hero-part2 {
  padding-top: 0.5rem !important;
}

@media only screen and (min-width: 1910px) {
  .services-hero-part {
    padding: 12rem 17rem 6rem !important;
  }
  .services-hero-part2 {
    padding: 2rem 17rem 1rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .services-hero-part {
    padding-top: 7rem !important;
    padding-bottom: 3rem !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .services-hero-part2 {
    padding-top: 7rem !important;
    padding-bottom: 3rem !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.career-card1 {
  padding: 1rem 7rem 2rem 4rem;
}

.career-card-12 {
  position: relative;
}

.career-2 {
  border: 2px solid transparent;
  padding: 20px;
  background-color: var(--secondary-bg);
  text-align: left;
  margin-bottom: 30px;
  position: relative;
}

.career-2::before,
.career-2::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background: var(--secondary-bg);
  transition: 0.5s;
  z-index: -1;
  border: 2px solid var(--primary-bg);
}

.career-2::before {
  height: 80%;
  top: 10%;
}
.career-2::after {
  height: 90%;
  top: 5%;
}
.career-2:hover:before {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #002549;
  transform: translateX(30px);
}
.career-2:hover:after {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #002549;
  transform: translateX(15px);
}

.career-2:hover {
  border: 2px solid #002549;
}

.career-img2 {
  margin-top: 26px;
  width: 95%;
  left: 5%;
  position: sticky;
  border-radius: 20px;
  top: 0px;
}

@media only screen and (min-width: 1910px) {
  .career-card1 {
    padding: 40px 14rem;
  }
}

@media only screen and (max-width: 767px) {
  .career-card1 {
    padding: 35px 30px;
  }
  .career-img2 {
    width: 100%;
    margin-top: 0px;
  }
}

.core-values-container {
  display: flex;
  height: 400px;
}
.core-values-part {
  padding: 40px 80px 40px;
  --bs-gutter-x: 0;
  background: aliceblue;
}

.core-item {
  cursor: pointer;
  width: 20%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ddd;
  transition: all 0.3s ease;
  transform: rotate(0deg);
  color: white;
}

.core-value-right {
  display: flex;
  height: 100%;
  padding-left: 5%;
  justify-content: center;
  flex-direction: column;
}

.core-item.hovered {
  width: 50%;
}

.core-item:nth-child(1) {
  background-color: #264653;
}

.core-item:nth-child(2) {
  background-color: #2a9d8f;
}
.core-item:nth-child(3) {
  background-color: #e9c46a;
}
.core-item:nth-child(4) {
  background-color: #f4a261;
}
.core-item:nth-child(5) {
  background-color: #e76f51;
}

/* Add more rules for additional items */

.core-item.hovered .core-heading {
  height: 14rem;
}

.core-item.hovered .core-paragraph {
  transform: rotate(0deg);
  left: 1rem;
  top: 0;
  position: relative;
}

.core-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  position: absolute;
  margin-bottom: 10px;
  transform: rotate(-90deg);
  display: flex;
  height: 6rem;
  text-transform: uppercase;
}

.core-paragraph {
  width: 11vw;
  font-size: 14px;
  position: absolute;
  top: 25%;
  bottom: 25%;
  display: none;
  font-family: "Open Sans", sans-serif;
  color: white !important;
  margin-left: 15px;
}

.core-item:hover .core-paragraph {
  display: block; /* Show the paragraph on hover */
}

.hovered .core-paragraph {
  transform: rotate(90deg);
}

@media only screen and (min-width: 1910px) {
  .core-values-part {
    padding: 60px 15rem;
  }
}
@media only screen and (max-width: 767px) {
  .core-values-part {
    padding: 40px 20px 20px;
  }

  .mob-core-value-card {
    padding: 20px;
    box-shadow: 0px 0px 5px var(--primary-bg);
    border-radius: 10px;
    margin: 40px 16px;
    background: var(--primary-bg);
    color: white;
  }

  .mob-core-value-head {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .mob-core-value-para {
    font-size: 18px;
  }
}

.hero-other-part {
  min-height: 30rem;
  background-size: cover;
  min-height: 24rem;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  place-items: center;
}

.hero-other-title {
  font-weight: 400;
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
  font-family: "Philosopher";
  font-size: 60px;
}

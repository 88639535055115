.paragraph-blog {
  font-family: "Open Sans";
  letter-spacing: normal !important;
  font-size: 16px !important;
  line-height: 28px;
  opacity: 85% !important;
  color: var(--text) !important;
  margin-top: 20px;
}

.heading-blog {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.single-blog-part {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.Single-blog-section {
  width: 70%;
  padding: 60px 40px;
  padding-top: 0px;
  padding-right: 50px;
  background: aliceblue;
  margin-top: 40px;
  margin-left: 105px;
  margin-bottom: 40px;
  box-shadow: 0px 0px 1px grey;
}

.blog-links {
  width: 30%;
  padding: 50px;
  padding-left: 0px;
}

.blog-body {
  font-family: "Open Sans";
  font-size: 14px;
  padding-top: 10px;
  line-height: 170%;
}

@media only screen and (max-width: 767px) {
  .single-blog-part {
    flex-direction: column;
  }
  .Single-blog-section {
    padding: 60px 80px;
    width: 100%;
  }
  .blog-links {
    width: 100%;
    padding: 30px;
    padding-top: 0px;
  }
}

.blog-link {
  font-family: "Open Sans";
  color: #002549;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  position: sticky !important;
  position: -webkit-sticky !important;
}

.blog-hand {
  height: 40px;
  width: 45px;
  margin-bottom: -11px;
  margin-right: 5px;
}

.blog-link:hover {
  text-decoration: underline;
  color: var(--text) !important;
}

@media only screen and (min-width: 1910px) {
  .single-blog-part {
    padding: 0px 6rem 0px 10rem;
  }
  .blog-links {
    /* padding-left: 50px; */
  }
}

/* ------------ */

.Single-blog-image {
  box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.15),
    0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.15) !important;
}

.cardeebody {
  padding: 0px;
  height: 540px;
  width: 100%;
  background-color: var(--text-white);
  box-shadow: 0px 0px 2px grey;
  width: 32%;
}

.cardeebody:hover {
  /* padding: 3%; */
  box-shadow: 1px 1px 8px #002549 !important;
  transition: 1ms;

  /* text-shadow: 2px 2px 5px #ffe053; */
}

@media only screen and (max-width: 767px) {
  .cardeebody {
    width: 100%;
    height: fit-content !important;
    margin-bottom: 15px;
  }
}

.blogcardbody {
  padding: 10px;
}

.blog-card-image {
  width: -webkit-fill-available;
  height: 100%;
}

.blogheadd {
  color: #002549;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 26px;
  text-align: left;
}

.wpo-blog-area {
  background: #ffffff;
}

.blogs-part {
  padding: 3rem 4rem 1rem 7rem;
  background-color: aliceblue;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.title-blog {
  padding-left: 0px !important;
  opacity: 100;
  color: #002549 !important;
  font-size: 64px !important;
  margin-bottom: 30px !important;
  padding-top: 40px !important;
  font-family: "Open Sans";
  font-weight: normal;
  line-height: 1em;
  font-weight: 800;
}

@media only screen and (min-width: 1910px) {
  .blogs-part {
    padding: 3rem 14rem 4rem 17rem;
    gap: 20px;
  }

  .title-blog {
    opacity: 100;
    color: var(--text) !important;
    font-size: 64px !important;
    margin-bottom: 30px !important;
    padding-top: 40px !important;
  }
  .cardeebody {
    height: 540px;
  }
}

@media only screen and (max-width: 767px) {
  .Single-blog-section {
    padding: 45px 30px;
    margin: 0px;
  }
  .title-blog {
    font-size: 50px !important;
    padding-top: 0px !important;
  }
  .blogs-part {
    padding: 40px 30px;
  }
}

.wpo-blog-img img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.wpo-blog-text ul {
  display: flex;
}

.wpo-blog-text h2 {
  font-size: 16px;
  color: #233849;
  line-height: 26px;

  padding-top: 15px;
}

.wpo-blog-text h2 a {
  color: #233849;
  font-weight: 600;
  font-family: "Conv_Now-Bold";
}

.wpo-blog-text h2 a:hover {
  color: #c8a770;
}

.wpo-blog-text {
  padding: 13px 0px 9px 12px;
}

.wpo-blog-text a {
  color: #c8a770;
}

.wpo-blog-text ul li {
  color: #989696;
}

.wpo-blog-text ul li span {
  color: var(--text);
  display: block;
  padding-right: 5px;
  font-size: 25px;
  font-weight: bold;
}

.wpo-blog-wrap-outer {
  background-color: var(--text-white);
  box-shadow: 0px 0px 6px #0abcc2;
  border-radius: 15px;
  width: 30%;
  margin-right: 1%;
  padding: 0px;
  margin-left: 2%;
}

.bolgimage {
  height: 45%;
}

.blog-content {
  height: 55%;
}

@media (max-width: 992px) {
  .wpo-blog-wrap {
    background: #233849;
    border-radius: 5px;
  }

  .wpo-blog-text {
    padding-top: 0;
    background: white;
    border-radius: 5px;
  }

  .wpo-blog-text h2 a {
    color: #f1f1f1;
    text-decoration: none;
    font-size: 22px;
    line-height: 30px;
  }

  .wpo-blog-area {
    padding-bottom: 10px;
  }

  .wpo-blog-img img {
    width: 100%;
  }

  .wpo-blog-text ul li {
    padding-top: 10px;
    color: #f1f1f1;
  }

  .wpo-blog-wrap-outer {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1400px) {
  .cardeebody {
    height: 570px;
  }
}

@media only screen and (max-width: 1236px) {
  .cardeebody {
    height: 670px;
  }
}

.who-we-are-part {
  padding: 130px 110px;
  display: flex;
  gap: 5%;
  background-color: #e9fbff6e;
  background-image: url(./whowearebg3.jpg);
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
}

.who-we-are-left {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.who-we-are-right {
  width: 60%;
  position: relative;
}

/* .who-we-are-title::after {
  background: url(./who.png);
  content: "";
  z-index: 1;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0px;
  left: -5rem;
  background-size: cover;
  background-repeat: no-repeat;
} */

.who-we-are-title {
  z-index: 2;
  /* font-size: 50px !important; */
}

.who-we-are-right::after {
  background-image: url();
  position: absolute;
  content: "";
  height: 140%;
  width: 50%;
  background: transparent url(./WhoWeAreImg2.jpg) no-repeat left top;
  left: 50%;
  top: -20%;
  background-size: 460px auto;
  z-index: 1;
  display: none;
}

.who-we-are-img {
  z-index: 2;
  position: relative;
  width: 80%;
  left: 10%;
  display: none;
}

.react-player {
  pointer-events: none;
}

.who-we-are-right .who-we-img2 {
  position: absolute;
  right: 0;
}

@media only screen and (min-width: 1910px) {
  .who-we-are-part {
    padding: 130px 17rem;
  }
}

@media only screen and (max-width: 1136px) {
  .react-player {
    width: 500px !important;
    height: 281px !important;
  }
}

@media only screen and (max-width: 767px) {
  .who-we-are-part {
    padding: 40px 30px;
    flex-direction: column;
    padding-bottom: 0px;
    height: fit-content;
  }
  .who-we-are-left {
    width: 100%;
  }
  .who-we-are-right {
    width: 100%;
    margin: 30px 0px;
  }

  .who-we-are-right::after {
    width: 112%;
    left: -20px;
    top: 50px;
    height: 90%;
    display: none;
  }
  .react-player {
    width: 334px !important;
    height: 188px !important;
    margin: auto;
  }
  .who-we-are-img {
    width: 100%;
    left: 0%;
    top: -15px;
  }
}

.footer-box {
  padding: 70px 99px 43px 108px;
  background: black;
}

@media only screen and (min-width: 1910px) {
  .footer-box {
    padding: 40px 17rem;
  }
}

.subscribe-btn {
  width: 50%;
  display: flex;
  color: var(--text-white);
  text-transform: uppercase;
  justify-content: start;
  gap: 10px;
  font-family: "Open sans";
}

.footer-logo {
  max-width: 70%;
  margin-left: -0.4rem;
}

.subscribe-input {
  width: 50%;
  position: relative;
  height: 100%;
  background: transparent !important;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0px !important;
  color: var(--text-white);
  opacity: 100%;
  padding: 0px;
}

.subscribe-input:focus {
  border: 0px;
  border-bottom: 1px solid white;
  outline: 0px !important;
  box-shadow: none;
}

.footer-links-section {
  margin-left: 150px;
}

.footer-links-section-2 {
  margin-left: 50px;
}

.footer_links {
  font-size: 18px;
  line-height: 44px;
  font-family: "Open Sans";
}

.text-underline {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.text-underline:after {
  content: "";
  position: absolute;
  border-bottom: 2px solid #fff5e9;
  top: 85%;
  transition: all 0.4s;
  right: 100%;
  left: 0;
}

.timeline-item-content:hover .text-underline:after {
  right: 0;
}

.copy-box-home-vision {
  text-align: left;
  background-color: var(--text-white) !important;
  color: var(--text) !important;
  z-index: 9;
  margin-right: 20px !important;
  font-family: "Open Sans";
  font-weight: 900;
  border: 2px solid #002549;
  border-radius: 8px;
}

.footer-tooltip {
  text-align: left;
  background-color: var(--text-white) !important;
  color: var(--text) !important;
  z-index: 9;
  margin-right: 20px !important;
  font-family: "Open Sans";
  font-weight: 900;
  border: 2px solid #002549;
  border-radius: 8px;
  width: fit-content;
}

.swal2-styled.swal2-confirm {
  background-color: #002549;
}

.t-footer-icon:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.t-footer-icon:hover .fb {
  color: #3b5998;
  transform: scale(1.2);
}

.t-footer-icon:hover .yt {
  color: #cd201f;
  transform: scale(1.2);
}

.t-footer-icon:hover .twitter {
  color: #00acee;
  transform: scale(1.2);
}
.t-footer-icon:hover .insta {
  color: #4f5bd5;
  transform: scale(1.2);
}

.blog-hand1 {
  height: 33px;
  width: 33px;
  margin-bottom: -11px;
  margin-right: 5px;
}

@media only screen and (max-width: 767px) {
  .footer-box {
    padding: 40px 30px;
  }

  .subscribe-btn {
    width: 100%;
    left: 0px;
  }

  .subscribe-input {
    width: 100%;
    left: 0px;
  }

  .footer-links-section {
    margin-left: 0px;
  }
  .footer-links-section-2 {
    margin-left: 0px;
  }
}

/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.r-services-part {
  /* padding: 25px 115px 40px;
  background: #e0f0fe; */
}

.rservices-main {
  display: flex;
  gap: 0%;
  position: relative;
  flex-direction: column;
}

.rservices-left {
  width: 100%;
  background: white;
  padding: 60px 40px 50px 110px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.rservices-left-part {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
}

.rservices-left-part-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rservices-right {
  width: 100%;
  padding: 60px 110px 60px 40px;
  background: linear-gradient(
    92deg,
    var(--primary-bg) -300%,
    var(--text-white)
  );
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.learn-more-btn {
  background-color: var(--primary-btn);
  padding: 12px 25px;
  text-transform: uppercase;
  color: var(--text-white);
  margin-top: 20px;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}

.learn-more-btn2 {
  background-color: var(--text-white);
  padding: 12px 25px;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}

.gold-product-component1 {
  display: flex;
  flex-direction: row;
  background-attachment: fixed;
  gap: 60px;
  padding-bottom: 30px;
}

.gold-product-component1 .main-title {
  text-align: left;
  margin-bottom: 0px;
}

.gold-product-component-image-div {
  display: flex;
  width: 35%;
}

.gold-product-component-content {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.gold-product-component-image1 {
  z-index: 2;
  background: var(--text-white);
  box-shadow: 0px 0px 2px var(--primary-bg);
  width: 100%;
}

.about-us-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 35px;
}

.category-items {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 1%;
  margin-top: 30px;
  flex-wrap: wrap;
}

.rservices-image {
  width: 98%;
  margin: 0px auto;
  margin-right: 2%;
  transform: scale(0.8);
}

.category-content2 {
  margin-top: 5%;
}

.category-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 10px;
  margin: 25px 0px;
}

.category-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.category-item {
  cursor: pointer;
  font-size: 42px;
  margin-bottom: 20px;
}

.core-inner {
  width: 90%;
  margin-right: 10%;
}

.carousel-button1 {
  color: var(--text-white);
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  margin-top: 30px;
}

.carousel-button {
  border: none;
  background-color: var(--primary-btn);
  font-size: 24px;
  cursor: pointer;
  padding: 0px 12px;
  border-radius: 52px;
}

.carousel-button-previous {
  margin-right: 8px;
  position: absolute;
  left: 8%;
}

.carousel-button-next {
  margin-left: 8px;
  position: absolute;
  right: 8%;
}

.slick-next {
  right: -15px !important;
  background-color: transparent !important;
  border-radius: 30px;
  width: 35px !important;
  height: 35px !important;
}

.slick-prev {
  left: -45px !important;
  background-color: transparent !important;
  border-radius: 30px;
  width: 35px !important;
  height: 35px !important;
  z-index: 9;
}
.slick-prev:before {
  font-size: 40px !important;
  color: #0484cc !important;
}
.slick-next:before {
  font-size: 40px !important;
  color: #0484cc !important;
}

/* .super-head {
  text-shadow: -3px 2px 2px #101c4c !important;
} */

.r-services-top {
  background-image: url(./Media/supermarket22.png);
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .gold-product-component {
    padding: 50px 19rem;
  }
  .r-services-part {
    /* padding: 20px 17rem 40px; */
  }

  .rservices-left {
    padding: 40px 16rem 40px 16rem;
  }

  .rservices-right {
    padding: 40px 16rem 40px 16rem;
  }

  .carousel-button-previous {
    left: 15%;
  }

  .carousel-button-next {
    right: 15%;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .gold-product-component {
    flex-direction: column;
    width: 100%;
    padding: 50px 30px;
  }
  .gold-product-component1 {
    flex-direction: column;
    width: 100%;
  }
  .gold-product-component-content {
    width: 100%;
  }

  .gold-product-component-image-div {
    width: 100%;
  }
  .gold-product-component-image {
    width: 100%;
    height: 330px;
  }
  .gold-product-component-image1 {
    width: 100%;
    height: 330px;
  }
  .bg-div {
    width: 84%;
    height: 330px;
  }
  .bg-div1 {
    width: 85%;
    height: 330px;
  }
  .comp2-content {
    order: 1;
  }
  .comp2-image {
    order: 2;
  }
  .r-services-part {
    padding: 0px 0px;
    flex-direction: column;
  }

  .core-inner {
    width: 100%;
    margin-right: 0px;
  }

  .slick-next {
    right: -10px !important;
  }

  .slick-prev {
    left: -10px !important;
  }
  .rservices-left {
    flex-direction: column;
    padding: 0px 0px;
  }
  .rservices-left-part {
    width: 100%;
    height: fit-content;
    padding: 30px;
  }

  .rservices-right {
    flex-direction: column;
    padding: 0px 0px;
  }

  .rservices-left-part-right {
    width: 100%;
  }

  .ssol-mob-img {
    order: 2;
  }
  .ssol-mob-content {
    order: 1;
  }
}

.hero-btn12 {
  font-family: "Open sans", Sans-serif;
  font-size: 16px;
  color: var(--text-white);
  font-weight: 600;
  display: flex;
  position: relative;
}

.hero-btn12:hover span {
  transform: translateX(10px);
}

.hero-btn12:hover {
  color: #00a9f4;
}

.btn-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #0087cae3 0, #0087ca94 10%, white 20%);
  background-position: 0;
  -webkit-background-clip: text;
  background-clip: text; /* Add standard background-clip property */
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

.pulse {
  color: var(--text-white);
  text-decoration: none;
  display: inline-block;
  border-radius: 15px;
  box-shadow: 0 1px 2px 0 rgba(3, 6, 26, 0.15);
  transition: 0.5s all ease-in-out;
  font-size: 22px;
  font-weight: 800;
}

.pulse:hover {
  cursor: pointer;
  background: transparent;
  color: var(--text-white);

  animation: none;
}

.btn-pulse {
  animation: pulse 2s infinite 3s cubic-bezier(0, 0, 0.61, 0.18);
}

.home-slide-img {
  width: 80% !important;
  margin: auto;
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0); /* Increased box-shadow size to 14px */
  }
}

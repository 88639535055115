@media only screen and (max-width: 767px) {
  .recruitment-title {
    font-size: 40px !important;
    line-height: 50px;
    margin-bottom: 30px;
  }
}

.recruitment-title {
  font-family: "Open Sans", sans-serif;
  font-size: 55px;
  margin-top: 3rem;
  font-weight: 600;
  color: var(--text-white);
}

.Hero-Recruitment {
  display: flex;
  padding: 5rem;
  justify-content: center;
  flex-direction: column;
  place-items: center;
  background-image: url(./../home/History/23.png);
  background: var(--primary-bg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  padding-top: 90px;
  margin-bottom: 30px;
}

.Recruitment1 {
  padding-bottom: 3rem;
}

.Contact-part {
  padding: 3rem 4rem 3rem 5rem;
}

.contact-link {
  border: 2px solid transparent;
  padding: 10px;
}

.contact-link-icon {
  background: var(--pent-bg);
  border-radius: 4px;
  box-shadow: 0px 0px 2px grey;
}

.contact-link:hover {
  border: 2px solid var(--quad-bg);
  border-radius: 4px;
  background-color: var(--quad-bg);
}

.contact-link:hover .contact-link-icon {
  background: var(--pent-bg);
}

.contact-link:hover .contact-link-icon .fill-current {
  color: var(--text);
}

.contact-link:hover h4 {
  color: var(--text-white);
}

.contact-link:hover .text-underline1:after {
  content: "";
  position: absolute;
  border-bottom: 2px solid black;
  top: 85%;
  transition: all 0.4s;
  right: 100%;
  left: 0;
}

.contact-form-box {
  border: 2px solid transparent;
  padding: 30px;
  background-color: var(--quad-bg);
  text-align: left;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0px 0px 1px grey;
  border-radius: 10px;
}

.contact-form-box::before,
.contact-form-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--quad-bg);
  transition: 0.5s;
  z-index: -1;
  border: 2px solid #002549;
  border-radius: 10px;
}
.contact-form-box:hover::before {
  transform: scale(1.1);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #002549;
  left: 10%;
  width: 90%;
  height: 90%;
}
.contact-form-box:hover::after {
  transform: scale(1.05);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #002549;
  left: 5%;
  width: 95%;
  height: 95%;
}

.contact-form-box:focus::before {
  transform: rotate(5deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #002549;
}
.contact-form-box:focus::after {
  transform: rotate(-5deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #002549;
}

.contact-form-box:hover {
  border: 2px solid #002549;
}

.contact-form-box input {
  border-radius: 8px;
}

.contact-form-box textarea {
  border-radius: 8px;
}

.copy-box-home-vision1 {
  text-align: left;
  background-color: aliceblue !important;
  color: var(--text) !important;
  z-index: 9;
  margin-right: 20px !important;
  font-family: "Open Sans";
  font-weight: 900;
  /* border: 1px solid #002549; */
  box-shadow: 0px 0px 2px grey;
  border-radius: 8px;
}

@media only screen and (min-width: 1910px) {
  .Contact-part {
    padding: 3rem 14rem 40px 15rem;
  }
}

@media only screen and (max-width: 767px) {
  .Contact-part {
    padding: 0rem 20px;
    padding-top: 40px;
  }
}

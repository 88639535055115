/* -------------********************************---------------- 
                          MAIN STYLING 
--------------- ********************************---------------- */
/* -------------********************************---------------- 
    Styling for responsiveness can be found at the end of the document
 -------------********************************----------------  */

.indi-domain-part {
  padding-bottom: 40px;
  display: flex;
  gap: 20px;
}

.indi-left {
  width: 30%;
  padding: 20px;
  background: white;
  border-left: 6px solid #54b444;
  padding-left: 0px;
  position: sticky;
  top: 78px;
  height: fit-content;
}

.indi-right {
  width: 100%;
}

.indi-right-box-part {
  display: flex;
  gap: 20px;
}

.indi-right-content-part {
  padding-left: 30px;
}

.indi-righ-box {
  width: 23%;
  border-radius: 10px;
  box-shadow: -0.0625rem 0 0.625rem 0 rgb(0 0 0 / 15%),
    0.3125rem 1.25rem 2.5rem 0 rgb(0 0 0 / 15%);
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 10px;
  border: 4px solid transparent;
  margin-top: 20px;
}

.indi-righ-box:hover {
  border-color: #fbcb04 !important;
  box-shadow: 0.1875rem 0.1875rem 0.625rem -0.125rem rgba(0, 0, 0, 0.09) !important;
  border: solid;
}

.domain-link {
  border-bottom: 1px solid grey;
  color: grey;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 15px;
  position: sticky !important;
  position: -webkit-sticky !important;
  font-family: "Metropolis-medium";
}

.indi-righ-box-icon {
  font-size: 70px;
}

#chartdiv {
  width: 100%;
  height: 500px;
}

.domain-list-item {
  border-bottom: 1px dotted grey;
  padding: 10px 10px 10px 0px;
}

.current-service {
  color: var(--text);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
}

.main-service {
  position: absolute;
  bottom: 20px;
  background: white;
  padding: 10px 30px;
  font-size: 45px;
  font-weight: 800;
  color: var(--text-white);
  background: #54b444 !important;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
}

.service-title {
  padding: 20px 0px;
  font-size: 45px;
  font-weight: 800;
  color: var(--text);
  font-family: "Open Sans", sans-serif;
}

.service-img-div {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust the height of your banner as needed */
}

.service-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Adjust the height as needed */

  /* Ensure the image doesn't overflow its container */
  object-fit: cover;
  object-position: center;

  /* If you want to overlay other content on top of the image, adjust the z-index as needed */
  z-index: -1;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .indi-left {
    width: 21%;
  }

  .indi-right {
    width: 100%;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .indi-domain-part {
    flex-direction: column;
    padding-top: 30px;
    padding: 0px;
  }

  .domains-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
  }

  .domain-list-item {
    margin-bottom: 4px;
  }

  .indi-left {
    width: 100%;
    padding: 20px;
    border: 1px solid black;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
  }

  .indi-right {
    width: 100%;
  }
  .indi-right-box-part {
    flex-wrap: wrap;
  }
  .indi-righ-box {
    width: 47%;
  }

  .main-service {
    font-size: 22px;
  }

  .indi-right-content-part {
    padding: 0px 30px 40px;
  }

  .service-title {
    font-size: 30px;
  }
}

/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.company-section {
  padding: 40px 80px 60px;
  padding-top: 50px;

  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
}
.paragraph-about {
  font-family: "Metropolis-regular" !important;
  font-size: 18px !important;
  line-height: 34px !important;
  margin-top: 0px;
  color: var(--text) !important;
  opacity: 90%;
  letter-spacing: 1px;
}

.vision-inner-div {
  display: flex;
  text-align: center;
  gap: 5%;
  justify-content: center;
  margin-top: 40px;
  padding: 40px 0px;
  border-top: 1px solid #54b444;
  border-bottom: 1px solid #54b444;
}

.vision-inner-div1 {
  padding: 8px;
  border: 1px solid black;
  width: 19%;
  border-radius: 1047px;
  cursor: pointer;
}

.vision-inner-div2 {
  border: 1px solid black;
  border-radius: 153px;
  padding: 10px;
  height: 240px;
}

.copy-box-home-vision {
  width: 400px !important;
  text-align: left;
  background-color: var(--text-white) !important;
  border: 1px solid black;
  color: var(--text-white);
  z-index: 9;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .company-section {
    padding: 40px 16rem;
    padding-top: 50px;
  }
  .vision-inner-div1 {
    width: 18%;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .company-section {
    padding: 60px 30px;
    padding-top: 40px;
  }
  .paragraph-about {
    font-size: 18px !important;
    line-height: 28px !important;
  }
  .vision-inner-div {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .vision-inner-div1 {
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
  }
  .vision-inner-div2 {
    height: 225px;
  }
  .copy-box-home-vision {
    width: 80%;
  }

  .circle-para {
    font-size: 16px !important;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Iphone SE - Max - 375px 
--------------- ********************************---------------- */

@media only screen and (max-width: 400px) {
  .vision-inner-div1 {
    width: 77%;
  }
}

.solutions-hero-card {
  padding: 30px 15px;
  width: 21%;
  margin-top: 30px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  transition: 0.7s;
  overflow: hidden;
  position: relative;
  background-color: var(--text-white);
}

.solutions-hero-card:hover {
  background: #eeeeee;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.solutions-hero-card:hover::before {
  background: rgb(85 108 214 / 10%);
}

.solutions-hero-card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: rotate(42deg);
  right: -90px;
  top: -23px;
  border-radius: 35px;
}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 11rem;
  border-radius: 50%;
  transform: rotate(96deg);
  left: -18rem;
  transition: 0.7s;
}

.solutions-hero-card:hover .hover_color_bubble {
  top: 0rem;
}
/* 
.solutions-hero-card:hover .heading {
  color: var(--text-white);
}

.solutions-hero-card:hover .paragraph {
  color: var(--text-white) !important;
} */

@media only screen and (max-width: 767px) {
  .solutions-hero-card {
    width: 90%;
  }
}

.solutions-hero-card-hr {
  height: 3px;
  /* background-color: #173152 !important; */
  background: linear-gradient(90deg, #3373a0, #173152);
  border-radius: 50px;
  opacity: 100%;
  margin: 16px 0px;
}

.solutions-offers {
  padding: 0rem 5rem 3rem 6rem;
  background-color: aliceblue;
}

@media only screen and (min-width: 1910px) {
  .solutions-offers {
    padding: 40px 16rem;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .solutions-offers {
    padding: 40px 20px;
  }
}

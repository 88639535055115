.header-wrapper.white {
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  background: white;
}

#menu-nav {
  border-radius: 40px;
}

.dropdown-item:not(:last-child) {
  background-color: var(--text-white);
  display: flex;
  border-bottom: 0px;
}

.dropdown-item:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.dropdown-item:last-child {
  background-color: var(--text-white);
  display: flex;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.dropdown-item:hover {
  transition: 300ms ease-out;
}

.drop-text {
  color: var(--text);
}

.dropdown-item:hover .drop-text {
  color: #002549;
}

.td-icon {
  color: var(--text);
  transition: all 300ms ease-in-out;
}

.dropdown-item:hover .td-icon {
  /* animation-name: shake-hover; */
  animation-duration: 1s;
  animation-iteration-count: infinite;
  color: var(--text-primary);
}

.drop-text {
  margin-bottom: 0px;
}

.td-icon {
  font-size: 24px;
}

@keyframes shake-hover {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-8px);
  }
  20% {
    transform: translateX(6px);
  }
  30% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-2px);
  }
  60% {
    transform: translateX(1px);
  }
  70% {
    transform: translateX(-1px);
  }
  80% {
    transform: translateX(0.5px);
  }
  90% {
    transform: translateX(-0.5px);
  }
  100% {
    transform: translateX(0);
  }
}

.dropdown-icon {
  background-color: var(--text-white);
}
.dropdown-menu {
  background-color: transparent !important;
  border: none;
}

.Header-part {
  width: 100%;
}
@media (min-width: 1910px) {
  .Header-part {
    width: 100%;
    padding: 0px 16rem;
  }
}

.logo-container {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 12px;
}

.menu-container {
  background-color: var(--text-white);
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-weight: 600;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 22px !important;
}
.menu-icon-span {
  display: flex;
  align-items: center;
}
.menu-dropdown-icon {
}

.menu-icon:hover {
  transform: scale(1.1);
}

.menu-icon {
  color: var(--text-white);
}

.menu-icon.scrolled {
  color: var(--primary-bg);
}

.search-btn {
  color: var(--text-white);
}

.search-btn.scrolled {
  color: var(--text);
}

.menu-icon .line2 {
  width: 30px;
}

#menu-nav {
}

.dropdown-item {
}

/* #menu-nav::before {
  content: "";
  position: absolute;
  top: -20px;
  right: 40%;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
}

#menu-nav .addon::before {
  content: "";
  position: absolute;
  top: -20px;
  right: 40%;
  border: 10px solid transparent;
  border-bottom: 10px solid #002549;
} */

/* .d-menu::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0%;
  border: 10px solid transparent;
  border-bottom: 10px solid #002549;
}

.d-menu.addon::before {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #002549;
}

.d-menu-who::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0%;
  border: 10px solid transparent;
  border-bottom: 10px solid #002549;
}

.d-menu-who.addon::before {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #002549;
} */

/* .menu-icon #menu-nav {
  background-color: var(--text);
  position: absolute;
  top: 100%;
  right: -2rem;
  width: 200px;
  display: grid;
  align-content: center;
} */

.menu-list a {
  padding: 15px 0 15px 15px;
  text-decoration: none;
  color: var(--text-white);
  font-size: 0.95rem;
  font-weight: 500;
  transition: 0.5s;
}

.menu-list a:hover .drop-text {
  /* padding-left: 10px; */
  transition: 300ms ease-in-out;
  color: var(--text-primary);
}

.dropdown-icon {
  margin-left: 50px;
}

/* -------- New Nav Bar -------  */
.new-nav-box {
  display: flex;
  width: fit-content;
  background-color: var(--text-white);
  border-radius: 12px;
}

.new-nav-item {
  width: 230px;
  padding: 25px 20px !important;
  border: 1px solid var(--primary-bg);
}

.new-nav-item:hover {
  background-color: var(--primary-bg);
  border: 1px solid var(--text-white);
}

.new-nav-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 160%;
  text-transform: none !important;
  color: var(--text);
}

.new-nav-heading {
  font-family: "Oswald";
  font-size: 18px;
  text-transform: capitalize !important;
  margin: 8px 0px 6px !important;
  color: var(--text);
  font-weight: 600;
}

.new-nav-item:hover .new-nav-heading {
  color: var(--text-white);
}
.new-nav-item:hover .new-nav-paragraph {
  color: var(--text-white);
}

.new-nav-box .new-nav-item:first-child:hover {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 11px;
}
.new-nav-box .new-nav-item:last-child:hover {
  border-bottom-right-radius: 11px;
  border-top-right-radius: 11px;
}

.new-nav-item:first-child {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 11px;
}

.new-nav-item:last-child {
  border-bottom-right-radius: 11px;
  border-top-right-radius: 11px;
}

.new-drop-icon-div {
  background-color: #002549;
  border-radius: 46px;
  padding: 10px;
  width: fit-content;
}

.new-nav-item:hover .new-drop-icon-div {
  background-color: var(--text-white);
}

.new-nav-item:hover .drop-icon {
  color: #002549;
}

.menu-icon:hover #menu-nav {
  position: absolute;
  padding-top: 1.5rem;
  width: fit-content;
  display: grid;
  align-content: center;
  right: -3.1rem;
  top: 27px;
}
/* .menu-icon:hover #menu-nav {
  right: 0rem;
} */

.menu-icon:hover #menu-nav-domains {
  position: absolute;
  padding-top: 1.5rem;
  left: -0.05rem;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  align-content: center;
  margin: 0px;
  top: 27px;
}

.menu-icon:hover #menu-nav-unused {
  background-color: var(--text);
  position: absolute;
  top: 86%;
  right: -2.875rem;
  width: 250px;
  display: grid;
  align-content: center;
}
.menu-icon:hover #menu-nav-unused {
  right: -2.795rem;
}

.menu-icon:hover #logged-in-nav {
  background-color: var(--text);
  position: absolute;
  top: 86%;
  right: 1.225rem;
  width: 200px;
  display: grid;
  align-content: center;
}

#domains {
  position: relative;
}

#company {
  position: relative;
}

#websuite {
  position: relative;
}

#logged-in {
  position: relative;
}

.show-text {
  opacity: 1;
  font-size: 3rem;
  transform: translateX(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  font-family: "Cinzel Decorative", cursive;
  margin-top: 5px;
  margin-left: -15px;
  color: var(--text-white);
}

.show-text.one {
  color: #002549;
}

.hide-text {
  opacity: 0;
  transform: translateX(-40px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  font-size: 3rem;
  font-family: "Cinzel Decorative", cursive;
  margin-top: 5px;
  margin-left: -15px;
  color: #002549;
}

.ml12 {
  display: inline-block;
}

.hamburger-react {
  position: absolute !important;
  right: 10px;
  top: 13px;
}

@media only screen and (max-width: 768px) {
  .mobile-div {
    content: "";
    position: fixed;
    width: 100%;
    height: 65px;
    display: block;
    background-color: transparent;
  }
  .mobile-div.white {
    background-color: aliceblue;
  }
}

:root {
  --text: black;
  --text-white: white;
  --text-gold: #fbcb04;
  --text-primary: #e76f51;
  --primary-bg: #264653;
  --secondary-bg: #e9c46a;
  --tertiary-bg: #ffdab9;
  --quad-bg: #2a9d8f;
  --pent-bg: #e76f51;
  --white-bg: white;
  --black-bg: black;
  --primary-btn: #2a9d8f;
  --secondary-btn: #e9c46a;
}

@media screen and (max-width: 700px) {
  .swiper-slide {
    flex-direction: column;
  }
  #last-bloginfo {
    padding-bottom: 30px;
  }
}

.solutions-icon {
  font-size: 45px;
}

.solutions-box {
  border: 2px solid transparent;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* .solutions-box:hover {
  border: 2px solid #002549;
} */

.solutions-box:before,
.solutions-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: aliceblue;
  transition: 0.5s;
  z-index: -1;
}
.solutions-box:hover:before {
  transform: rotate(-10deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}
.solutions-box:hover:after {
  transform: rotate(-5deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.solutions-box:hover .solutions-icon {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.text-hover-from-left {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  transition: all 0.4s;
  text-decoration: none;
  color: var(--text);
  font-weight: 600;
}
.text-hover-from-left:after {
  content: attr(data-link);
  background: #002549;
  color: var(--text-white);
  display: block;
  transition: all 0.4s;
  position: absolute;
  top: 0;
  left: -100%;
  padding: 5px 10px;
}

.solutions-box:hover .text-hover-from-left:after {
  left: 0;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -25px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
